<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col xl="6" lg="8" md="8" sm="10" xs="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Organisation du temps de travail" />
        </div>

        <!-- la barre de progression -->
        <v-row justify="center">
          <v-col xl="6" lg="8" md="8" sm="10" xs="12">
            <!-- la progess bar à afficher lors du chargement des données -->
            <v-progress-linear
              indeterminate
              :active="loading"
            ></v-progress-linear>
          </v-col>
        </v-row>

        <!-- le détail du service -->
        <DetailViewComponent title="Détail de l'organisation du temps de travail" :fields="fields" />
      </v-col>
    </v-row>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar>

  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DetailViewComponent from "@/components/ui/DetailViewComponent.vue";

import WorkingTimeOrganizationService from "@/service/dictionary/working_time_organization_service.js"

import * as exceptions from "@/service/exception_to_message.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { addFieldWithOneValue } from "@/components/ui/detail_view_component_tool.js";

export default {
  name: "DetailWorkingTimeOrganization",

  components: { Workflow, TitleAndReturnComponent, DetailViewComponent },
  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin,],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** le service de chargement des dictionnaires. */
      workingTimeOrganizationService: null,

      /**l'identifiant du motif maladie à visualiser. */
      workingTimeOrganizationId: null,

      /**Afficher le bouton de retour */
      showBackButton: false,

      /**les champs à afficher en détail */
      fields: [],
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        this.fields = [];
        let workingTimeOrganization = await this.workingTimeOrganizationService.getWorkingTimeOrganizationById(this.workingTimeOrganizationId);

        addFieldWithOneValue(this.fields, "nom", workingTimeOrganization.label, false);

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {},
  mounted() {
        // Initialisation du service datas
    this.workingTimeOrganizationService = new WorkingTimeOrganizationService(this.$api);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.workingTimeOrganizationId = this.$route.params.id;
    }

    //on affiche le bouton retour
    this.showBackButton = true;

    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>